import React from "react"
import './components.css'
import { useState, useRef } from 'react';
import Draggable from "react-draggable"


function OnboardSurveyUrls() {
    const timer = useRef(null)
    const [handle, setHandle] = useState("")
    const [passcode, setPasscode] = useState("")
    const [notFound, setNotFound] = useState(false)
    const [loginError, setLoginError] = useState(false)
    const [checking, setChecking] = useState(false)
    const [surveyList, setSurveyList] = useState([]);
    const [schedulerUrl, setSchedulerUrl] = useState(null)

    const [isLoggedIn, setIsLoggedIn] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setSurveyList([])
        if (!handle) { return }
        clearTimeout(timer.current); 
        setNotFound(false)
        setLoginError(false)
        setChecking(true)
        setIsLoggedIn(false);
        const response = await fetch(`/getOnboardSurveyUrls?handle=${handle}&passcode=${passcode}&url=${encodeURIComponent(window.location.href)}`, {})
            .then(response => response.json())
            .catch(err => console.log(err))

        setChecking(false);
        //console.log(response)

        if (response.constructor === Object) {
            if (response.participant && response.scheduler_token) {
                setSchedulerUrl(`${window.location.origin}/scheduler/${response.participant.ctf_event_key}?token=${response.scheduler_token}`)
            }
            setSurveyList(response.instruments)
            setLoginError(false);
            setIsLoggedIn(true);
            return
        } 
        // reset after timer
        setNotFound(true)
        setLoginError(true)
        timer.current = setTimeout(() => {
          setNotFound(false)
        }, 3000)
    }

    const hasSurveys = (Object.values(surveyList).length > 0);
    const moreThanOneSurvey = (Object.values(surveyList).length > 1);

    const pluralize = (singular, plural) => {
        return moreThanOneSurvey ? plural : singular
    }

    return (<>
        <div style={{fontSize: "30px", padding: "50px"}} className="container">
            <Draggable positionOffset={{x: '-50%', y: '-50%'}}
                       bounds={{left: -850, right: 850, top: -450, bottom: 500}}
                       handle=".tabHeadingBar">
                <div className="no-cursor tabDefaults tabSurveyUrls windows95Tab">
                    <div className="tabHeadingBar">
                        <div className="tabHeading">SaikoCTF Surveys</div>
                    </div>
                    <div className="surveyLookup">
                        <form onSubmit={handleSubmit}>
                            <div className="surveyUrlsForm">
                                <label>Handle:</label>
                                <input
                                    type="text"
                                    value={handle}
                                    onChange={(e) => {
                                        setHandle(e.target.value);
                                        setIsLoggedIn(false)
                                    }} />
                                <label>Passcode:</label>
                                <input
                                    type="text"
                                    value={passcode}
                                    onChange={(e) => {
                                        setPasscode(e.target.value);
                                        setIsLoggedIn(false)
                                    }} />
                                <input className="submit" type="submit" value="Submit"></input>
                                <div style={{color: "red", textAlign: "center", margin: "10px"}}
                                     className="passwordFormResponseText">{notFound ? "Not Found" : ""}</div>
                            </div>
                        </form>
                        <div className="container-flex col results">
                            {
                                checking && <div className={"heading"}>Looking for Surveys!</div>
                            }
                            {
                               isLoggedIn && !checking && (hasSurveys ?
                                    <div className={"heading"}>Surveys found!<br/><br/>
                                        {pluralize(
                                            "Click on the link below to complete the survey.",
                                            "Click on each link below to complete available surveys.")}</div>
                                    :
                                    <div className={"heading"}>You have no surveys needing completion.<br/>Thank you
                                        for your participation!</div>)
                            }

                            {
                                isLoggedIn && hasSurveys &&
                                <ol>
                                    {Object.values(surveyList).map(val =>
                                        (<li key={val.url}><a href={val.url} target={"_blank"}>{val.title}</a></li>)
                                    )}
                                </ol>
                            }
                            {
                                schedulerUrl && <div className={"heading"}><a href={schedulerUrl} target={"_blank"}>Have you scheduled SaikoCTF? Click Here!</a></div>
                            }
                        </div>
                    </div>
                </div>
            </Draggable>
        </div>
        </>
);
}

export default OnboardSurveyUrls;